import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "413Now"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <StaticQuery
          query={indexQuery}
          render={data => {
            const { author, social } = data.site.siteMetadata
            return (
              <Container>
                <Image
                  fixed={data.avatar.childImageSharp.fixed}
                  alt={author}
                  style={{
                    marginRight: 0,
                    marginBottom: 0,
                    minWidth: 50,
                    borderRadius: `100%`,
                  }}
                  imgStyle={{
                    borderRadius: `50%`,
                  }}
                />
              </Container>
            )
          }}
        />
        <h1>
          Welcome{" "}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>
        <p>
          413 Now is an independent source for news, events and happenings in
          the 413/Pioneer Valley/Western Mass Area.
        </p>

        <Link to="/blog/">
          <Button marginTop="35px">Go to Articles</Button>
        </Link>
      </Layout>
    )
  }
}

const indexQuery = graphql`
  query IndexQuery {
    avatar: file(absolutePath: { regex: "/springfield.jpg/" }) {
      childImageSharp {
        fixed(width: 400, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
`

export default IndexPage
